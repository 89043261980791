



























































import { Component, PropSync, Vue } from 'vue-property-decorator';

import FlagshipProfilingExitPollViewModel
  from '@/vue-app/view-models/components/flagship/flagship-profiling/flagship-profiling-exit-poll-view-model';

@Component({ name: 'FlagshipProfilingExitPoll' })
export default class FlagshipProfilingExitPoll extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  flagship_exit_poll_view_model = Vue.observable(
    new FlagshipProfilingExitPollViewModel(),
  );

  created() {
    this.flagship_exit_poll_view_model.initialize();
  }
}
