














































































import { Component, PropSync, Vue } from 'vue-property-decorator';

import FlagshipProfilingSowosWealthViewModel
  from '@/vue-app/view-models/components/flagship/flagship-profiling/flagship-profiling-sowos-wealth-view-model';

import FlagshipProfilingExitPoll
  from '@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingExitPoll.vue';

@Component({
  name: 'FlagshipProfilingSowosWealth',
  components: {
    FlagshipProfilingExitPoll,
    ContractSavings: () => import('@/vue-app/components/ContractSavings.vue'),
  },
})
export default class FlagshipProfilingSowosWealth extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  flagship_wealth_view_model = Vue.observable(
    new FlagshipProfilingSowosWealthViewModel(),
  );

  showContractSavingsModal() {
    this.synced_is_open = false;
    this.flagship_wealth_view_model.showContractSavingsModal();
  }

  closeModal() {
    this.synced_is_open = false;
    this.flagship_wealth_view_model.showPollModal();
  }
}
