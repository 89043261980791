import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';

// Application
import GetExitPollOptionsQuery
  from '@/modules/flagship/catalogs/exit-poll-options/application/queries/get-exit-poll-options-query';
import UpdateInvestorProfileCommand
  from '@/modules/flagship/investor-profile/investor-profile/application/commands/update-investor-profile-command';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';

// Domain
import {
  InvestorProfileDto,
} from '@/modules/flagship/investor-profile/investor-profile/domain/dtos/investor-profile-dto';
import {
  ExitPollOptionsEntity,
} from '@/modules/flagship/catalogs/exit-poll-options/domain/entities/exit-poll-options-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class FlagshipProfilingExitPollViewModel {
  @Inject(TYPES.GET_EXIT_POLL_OPTIONS_QUERY)
  private readonly get_exit_poll_options_query!: GetExitPollOptionsQuery;

  @Inject(TYPES.UPDATE_INVESTOR_PROFILE_COMMAND)
  private readonly update_investor_profile_command!: UpdateInvestorProfileCommand;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  readonly i18n_namespace = 'components.flagship.flagship-profiling.flagship_profiling_exit_poll';

  exit_poll_options: Array<ExitPollOptionsEntity> = [];

  exit_poll_options_selected = '';

  is_disabled = true;

  private investor_profile_dto: InvestorProfileDto = {
    id: '',
    exit_pool: {},
  }

  initialize = async () => {
    this.exit_poll_options = await this.exitPoll;
  }

  get exitPoll() {
    return this.get_exit_poll_options_query.execute();
  }

  exitPollOptionsSelectedChange = () => {
    this.is_disabled = false;
  }

  setExitPool= (exit_polls: Array<ExitPollOptionsEntity>) => {
    const a_exit_poll: Array<any> = [];

    a_exit_poll.push(exit_polls.find(
      (item) => item.name === this.exit_poll_options_selected,
    ));

    this.investor_profile_dto.exit_pool = {
      values: a_exit_poll,
    };
  }

  updateInformation = async () => {
    try {
      this.setExitPool(await this.exitPoll);

      const investor_profile = await this.get_investor_profile_query.execute();
      this.investor_profile_dto.id = investor_profile.id;

      await this.update_investor_profile_command.execute(this.investor_profile_dto);
      window.location.reload();
    } catch {
      this.messageNotifier.showErrorNotification(
        `${i18n.tc(`${this.i18n_namespace}.message_error`)}`,
      );
    }
  }
}
